// src/components/Home.js

import React, { useState, useEffect, useRef } from "react";
import "./Chat.component.css";
// import image from "../../image.png";
// import playicon from "../../play_icon.svg";
import close from "../../images/close_icon.svg";
import arrow from "../../images/arrow.svg";
import disbleArrow from "../../images/disable_arrow.png";
import micicon from "../../images/mic-icon.png";
// import {
//     MainContainer,
//     ChatContainer,
//     MessageList,
//     Message,
//     MessageInput,
//     TypingIndicator,
// } from "@chatscope/chat-ui-kit-react";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
} from "react-speech-recognition";
const Chat = () => {
    const API_KEY =
        "sk-lXVRfUxv8OJgqCBIvPpjh2lcLiIDVycTB3t5uVnJjTT3BlbkFJnyNU-GJctSgzh4jUtzOAqVPzs1uNEZvQiFqlQK4ccA";
    const systemContent = `
        Your name is Penny. 
        Your user name is sara

        response should be short and concise and point to the problem

        response tone 
        Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant
    
        response content
        Ensure that the response does not contain any special characters or symbols, only plain text and the response shoule be very short and point to point
    
        Penny's Identity
        Hello, I am Penny, your virtual assistant representing Penny Appeal. I am here to help you with any questions you may have about our organization, its mission, programs, and how you can get involved. Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant. Ensure that the response does not contain any special characters or symbols, only plain text.
    
        Penny Appeal Overview
        Penny Appeal is a UK-based international humanitarian charity that was founded in 2009 by Adeem Younis. The organization provides poverty relief across Asia, the Middle East, Africa, and the United Kingdom. Penny Appeal's ethos, "small change, big difference," focuses on providing sustainable solutions to poverty and supporting the most vulnerable communities worldwide.
    
        Mission and Vision
        Penny Appeal's mission is to alleviate poverty through sustainable development projects, emergency aid, and the empowerment of communities. The organization envisions a world where everyone has access to basic needs such as food, clean water, education, healthcare, and shelter. Penny Appeal is committed to creating a just and compassionate society by empowering people and communities to break the cycle of poverty.
    
        Core Values
        Penny Appeal is guided by core values that include compassion, integrity, transparency, and inclusivity. These values underpin the organization's approach to humanitarian work, ensuring that aid is delivered with respect for the dignity of all individuals, regardless of their background, religion, or ethnicity.
    
        Programs and Services
        OrphanKind: The OrphanKind program is designed to provide comprehensive care for orphans, ensuring they have access to safe and loving homes, nutritious food, quality education, and healthcare. OrphanKind operates in countries like Pakistan, Bangladesh, and The Gambia, building and maintaining orphan homes where children are raised in a nurturing environment with a focus on their holistic development.
    
        WaterAid: The WaterAid program focuses on providing access to clean and safe drinking water in regions where water scarcity is a significant issue. Penny Appeal constructs wells, hand pumps, and sanitation facilities to ensure that communities have reliable sources of clean water. The program also includes hygiene education to promote better health practices. WaterAid projects are active in countries such as Pakistan, Somalia, and Sudan.
    
        Feed Our World: Feed Our World is aimed at combating hunger and malnutrition. Penny Appeal distributes food parcels, hot meals, and nutritional support to impoverished families and individuals in areas affected by conflict, natural disasters, and extreme poverty. The program also includes long-term food security projects such as sustainable agriculture initiatives and the establishment of community kitchens.
    
        Education First: Education First works to improve access to education in underprivileged communities. This program involves building schools, providing learning materials, and offering scholarships to children who otherwise might not have the opportunity to receive an education. The focus is on breaking the cycle of poverty through education, ensuring that children have the tools they need to build a better future.
    
        Emergency Response: Penny Appeal’s Emergency Response program provides immediate relief in the aftermath of natural disasters, conflicts, and other emergencies. This includes distributing food, water, medical supplies, and temporary shelter to those affected. The program operates globally, with teams ready to deploy quickly to provide lifesaving assistance wherever it is needed.
    
        Domestic Programs: In addition to its international efforts, Penny Appeal also operates within the UK, providing support to homeless individuals, offering domestic abuse services, and running various community outreach programs. These programs are designed to address local issues, offering help to those in need close to home.
    
        Geographical Reach
        Penny Appeal operates in over 30 countries, including Pakistan, Bangladesh, India, Syria, Palestine, Yemen, Sudan, Somalia, and various countries in Sub-Saharan Africa. The organization’s work spans multiple continents, focusing on areas where the need is greatest.
    
        History and Background
        Penny Appeal was founded in 2009 by Adeem Younis with the aim of making charity affordable and accessible to everyone. The organization started with small-scale projects, such as building wells, but quickly expanded its operations globally. Today, Penny Appeal is one of the fastest-growing charities in the UK, recognized for its innovative approach to poverty relief and its ability to mobilize support for various causes.
    
        Funding and Donations
        Penny Appeal relies on donations from individuals, businesses, and institutions to fund its programs. The organization is transparent about its finances, regularly publishing reports on how donations are utilized. Penny Appeal also engages in various fundraising activities, encouraging community participation through events and campaigns.
    
        Volunteering and Community Engagement
        Penny Appeal encourages community engagement through volunteering opportunities. Volunteers can participate in fundraising events, awareness campaigns, and direct service activities both in the UK and internationally. The organization values the contributions of its volunteers and believes that community involvement is key to achieving its mission.
    
        Contact and Support
        Penny Appeal offers various ways for supporters to get involved, including donating, volunteering, and fundraising. The organization can be contacted through its website or social media channels for more information. Supporters can also sign up for newsletters to stay informed about ongoing projects and upcoming events.
    
        direct donation link 
        https://pennyappeal.org/appeal/thirst-relief?src=widget-thirstrelief-2023
        
        Penny’s Default Response
        If the information you are looking for is not covered here, Penny will respond with: "Sorry, I can't understand or find the information you're looking for. Please check with the official Penny Appeal website or contact us for more details."
        `;
    const [isChat, setIsChat] = useState();
    const [typing, setTyping] = useState(false);
    const [msg, setMsg] = useState("");
    const chatContainerRef = useRef(null);
    const [conversation, setConversation] = useState([
        {
            message:
                "Welcome to Penny Appeal , I am Penny your virtual assistant How can I help you today?",
            sender: "Penny",
            direction: "incoming",
        },
    ]);
    // const [text, setText] = useState('');
    // const fullText = 'Lorem ipsum dummy text blabla.';
    // const speed = 50;
    // useEffect(() => {
    //     let i = 0;
    //     const typeWriter = () => {
    //         if (i < fullText.length) {
    //             setText((prev) => prev + fullText.charAt(i));
    //             i++;
    //             setTimeout(typeWriter, speed);
    //         }
    //     };

    //     typeWriter(); // Start typing effect when component mounts
    // }, []);

    const handleOnChange = (e) => {
        setMsg(e.target.value);
    };
    const handleSend = () => {
        if (msg) {
            const newMessage = {
                message: msg,
                sender: "user",
                direction: "outgoing",
            };
            setConversation((prevConversation) => [...prevConversation, newMessage]);
            setTyping(true);
            setMsg("");

            setTimeout(() => {
                processMessageToChatGPT([...conversation, newMessage]);
            }, 5000);
        }
    };
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, ["messages"]);
    useEffect(() => {    
        chatContainerRef.current?.lastElementChild?.scrollIntoView()
    }, [conversation]);

    const processMessageToChatGPT = async (messageHistory) => {
        setMsg("");

        const apiMessage = messageHistory.map((msgObj) => {
            let role = msgObj.sender === "user" ? "user" : "assistant";
            return { role: role, content: msgObj.message };
        });

        const sytemMessage = {
            role: "system",
            content: systemContent,
        };

        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
            },
            body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [sytemMessage, ...apiMessage],
            }),
        });

        const data = await response.json();
        if (data) {
            const assistantMessage = {
                message: data.choices[0].message.content,
                sender: "Penny",
                direction: "incoming",
            };
            setTyping(false);
            setConversation((prevConversation) => [
                ...prevConversation,
                assistantMessage,
            ]);
        }
    };
    const navigate = useNavigate();

    return (
        <>
            <div className="home-container">
                {!isChat && (
                    <div className="container">
                        <div className="bottom-icon">
                            {/* <button>
                        <img src={playicon} alt="" />
                    </button> */}
                            <button onClick={() => navigate("/")}>
                                <img src={close} alt="" />
                            </button>
                        </div>

                        <div className="welcome-text">
                            <h1>Salaam, I am Penny</h1>
                            <h2>Welcome back. How can I help you today?</h2>
                        </div>
                        <div className="box_scroll">
                            <div className="options-container">
                                <div className="option bg-color">
                                    <h3>WaterAid</h3>

                                    <p className="desc">
                                        The WaterAid program focuses on providing access to clean and
                                        safe drinking water in regions
                                    </p>

                                    <button className="action-button">Donate Now</button>
                                </div>
                                <div className="option bg-color">
                                    <h3>Feed Our World</h3>

                                    <p className="desc">
                                        Penny Appeal distributes food parcels, hot meals, and
                                        nutritional support to impoverished families and individuals
                                        in areas affected by conflict, natural disasters, and extreme
                                        poverty
                                    </p>

                                    <button className="action-button">Donate Now</button>
                                </div>
                                <div className="option bg-color">
                                    <h3>Education</h3>

                                    <p className="desc">
                                        Education First works to improve access to education in
                                        underprivileged communities
                                    </p>

                                    <button className="action-button">Find out More</button>
                                </div>
                                <div className="option bg-color">
                                    <h3>Emergency</h3>

                                    <p className="desc">
                                        Emergency Response program provides immediate relief in the
                                        aftermath of natural disasters, conflicts, and other
                                        emergencies
                                    </p>

                                    <button className="action-button">Shop Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isChat && (
                    <div className="container">
                        <div className="bottom-icon chat-close">
                            {/* <button>
                        <img src={playicon} alt="" />
                    </button> */}
                            <button onClick={() => navigate("/")}>
                                <img src={close} alt="" />
                            </button>
                        </div>
                        <div className="chat-container">
                            {
                                <div className="messages"
                                    ref={chatContainerRef}
                                    onKeyDown={(e) => e.key === "Enter" && handleSend()}
                                    onKeyUp={(e) => e.key === "Enter" && handleSend()}
                                    tabIndex="0"
                                    style={{
                                        overflowY: 'auto',
                                    }}
                                >
                                    {conversation.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`message ${msg.direction === "outgoing" ? "outgoing" : "incoming"}`}
                                        >
                                            <div className="message-content"><p>{msg.message}</p></div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                )}
                {typing && <div className="typing">
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                </div>}
                <div className="message-input">
                    <input
                        type="text"
                        placeholder="Message"
                        value={msg}
                        onFocus={() => {
                            setIsChat(true);
                        }}
                        onChange={handleOnChange}
                        onKeyDown={(e) => e.key === "Enter" && handleSend()}
                    />
                    <button
                        disabled={msg == "" ? true : false}
                        className={`${msg == "" ? "disabled" : "send-button"}`}
                        onClick={() => handleSend()}
                        onKeyDown={(e) => e.key === "Enter" && handleSend()}
                    >
                        <img src={arrow} alt="" />
                    </button>
                    <button className="mic-icon"
                        onClick={() => navigate("/speak")}
                    >
                        <img src={micicon} alt="" />
                    </button>
                </div>
            </div>
            {/* <div>
                <p>{text}</p>
            </div> */}
        </>

    );
};

export default Chat;
