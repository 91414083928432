import { BrowserRouter, Routes, Route } from "react-router-dom";
import Chat from "./component/Chat/Chat.component";
import Home from './component/Home/Home.component';
import Speack from "./component/Speack/Speack.component";
import "bootstrap/dist/css/bootstrap.css";
import Landing from "./component/Landing/Landing.component";

function App() {
  const routes = [
    {
      path: "/home",
      name: "home",
      title: "home",
      element: <Home />,
    },
    {
      path: "/chat",
      name: "chat",
      title: "chat",
      element: <Chat />,
    },
    {
      path: "/speak",
      name: "speak",
      title: "speak",
      element: <Speack />,
    },
    {
      path: "/",
      element: <Landing />,
      title: "Landing",
      name: "Landing",
    }
  
  ];

  const createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.element ? (
        <Route
          path={route.path}
          element={route.element}
          key={`page-route-${index}`}
        />
      ) : null;
    });
    return retData;
  };
  return (
    <>
     <BrowserRouter>
      <Routes>
        {createRoutes()}
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
