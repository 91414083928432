import React, { useState } from 'react'
import "./Home.component.css";
import chaticon from '../../images/chat-icon.svg';
import keyboardicon from "../../images/keyboard-icon.svg"
import { useNavigate } from 'react-router-dom';

function Home() {

  const [isImageVisible, setIsImageVisible] = useState(false);
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClick = () => {
    setIsImageVisible(!isImageVisible);
  };
  return (
    <div className="full_screen">
      <div className='container'>
        <div className='home-section'>
          <div className="circle-1" onClick={handleClick}></div>
          {isImageVisible && (<div className='actions-button'>
            <button
              onClick={handleShow}
            >
              <img src={chaticon} alt='' />
            </button>
            <button
              onClick={() => navigate("/chat")}
            >
              <img src={keyboardicon} alt='' />
            </button>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default Home
