/** @format */

import React, { useEffect, useRef, useState } from "react";
import "./Speack.component.css";
import viewIcon from "../../images/view_icon.svg";
import image from "../../images/image.png";
import close from "../../images/close_icon.svg";
import playicon from "../../images/play_icon.svg";
import pauseicon from "../../images/push_icon.svg";
import fullscreenview from "../../images/full-screen-view.png";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
// import Modal from 'react-modal';

import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
const timeline = gsap.timeline({ repeat: -1, yoyo: true });

function Speack() {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const [response, setResponse] = useState();
  const [thread_id, setThread_id] = useState();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [playng, setPlayng] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [IntialState, setIntialState] = useState(
    !listening && !processing && !playng ? true : false
  );
  const [isProcessingNotCancle, setIsProcessingNotCancle] = useState(false);
  const audioElement = document.getElementById("audio");

  useGSAP(() => {
    const tl3 = gsap.timeline().from("#group-svg path", {
      delay: 0.5,
      y: 25,
      // opacity: 0,
      stagger: {
        amount: 0.4,
      },
      skewY: 20,
      onComplete: () =>
        gsap
          .timeline({
            repeat: -1,
            repeatDelay: 1,
          })
          .to(".penny-tittle svg", {
            scale: 0.95,
          })
          .to(".penny-tittle svg", { scale: 1 }),
    });
    const tl = gsap.timeline({
      repeat: -1,
      // yoyo: true,
      paused: true,
    });
    tl.to(
      ".circle2.thinking",
      {
        scale: 0.95,
      },
      "start1"
    )
      .to(
        ".circle2.thinking",
        {
          duration: 0.5,
          scale: 1,
        },
        "start2"
      )
      .to(
        ".circle2 .box12, .circle2 .box22",
        {
          scale: 0.95,
          stagger: {
            amount: 0.4,
          },
        },
        "start1"
      )
      .to(
        ".circle2 .box12, .circle2 .box22",
        {
          scale: 1,
          stagger: {
            amount: 0.4,
          },
        },
        "start2"
      );
    const tl2 = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    });
    tl2
      .to(".circle3 .box", {
        ease: "none",
        scale: 0.9,
        duration: 0.15,
        stagger: {
          amount: 0.5,
        },
      })
      .to(
        ".circle3 .box",
        {
          ease: "none",
          scale: 1,
          duration: 0.15,
          stagger: {
            amount: 0.5,
          },
        },
        "-=30%"
      );

    if (playng) {
      tl2.play();
    }
    if (processing) {
      tl.play();
    }
  }, [playng, processing]);

  // const dots = document.querySelectorAll('.processing');
  // const radius = 40;
  // const loaderCenter = 50;

  // dots.forEach((processing, i) => {
  //   const angle = (i / dots.length) * Math.PI * 2;
  //   const x = loaderCenter + Math.cos(angle) * radius - 10;
  //   const y = loaderCenter + Math.sin(angle) * radius - 10;
  //   processing.style.left = `${x}px`;
  //   processing.style.top = `${y}px`;
  // });
  // gsap.to(".processing ", {
  //   rotation: 360,
  //   duration: 2,
  //   ease: "linear",
  //   repeat: 5
  // });

  // const handleClick = () => {
  //   gsap.fromTo(
  //     { scale: 1 },
  //     { scale: 1.2, duration: 0.9, yoyo: true, ease: 'power1.inOut', repeat: -1 }
  //   );
  // };

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const API_KEY =
    "sk-lXVRfUxv8OJgqCBIvPpjh2lcLiIDVycTB3t5uVnJjTT3BlbkFJnyNU-GJctSgzh4jUtzOAqVPzs1uNEZvQiFqlQK4ccA";
  const systemContent = `
    Your name is Penny. 
    Your user name is sara

    response should be short and concise and point to the problem
    
    response tone 
    Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant

    response content
    Ensure that the response does not contain any special characters or symbols, only plain text

    Penny's Identity
    Hello, I am Penny, your virtual assistant representing Penny Appeal. I am here to help you with any questions you may have about our organization, its mission, programs, and how you can get involved. Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant. Ensure that the response does not contain any special characters or symbols, only plain text.

    Penny Appeal Overview
    Penny Appeal is a UK-based international humanitarian charity that was founded in 2009 by Adeem Younis. The organization provides poverty relief across Asia, the Middle East, Africa, and the United Kingdom. Penny Appeal's ethos, "small change, big difference," focuses on providing sustainable solutions to poverty and supporting the most vulnerable communities worldwide.

    Mission and Vision
    Penny Appeal's mission is to alleviate poverty through sustainable development projects, emergency aid, and the empowerment of communities. The organization envisions a world where everyone has access to basic needs such as food, clean water, education, healthcare, and shelter. Penny Appeal is committed to creating a just and compassionate society by empowering people and communities to break the cycle of poverty.

    Core Values
    Penny Appeal is guided by core values that include compassion, integrity, transparency, and inclusivity. These values underpin the organization's approach to humanitarian work, ensuring that aid is delivered with respect for the dignity of all individuals, regardless of their background, religion, or ethnicity.

    Programs and Services
    OrphanKind: The OrphanKind program is designed to provide comprehensive care for orphans, ensuring they have access to safe and loving homes, nutritious food, quality education, and healthcare. OrphanKind operates in countries like Pakistan, Bangladesh, and The Gambia, building and maintaining orphan homes where children are raised in a nurturing environment with a focus on their holistic development.

    WaterAid: The WaterAid program focuses on providing access , to clean and safe drinking water , in regions where water scarcity is a significant issue. Penny Appeal constructs wells, hand pumps, and sanitation facilities to ensure that communities have reliable sources of clean water. The program also includes hygiene education to promote better health practices. WaterAid projects are active in countries such as Pakistan, Somalia, and Sudan.

    Feed Our World: Feed Our World is aimed at combating hunger and malnutrition. Penny Appeal distributes food parcels, hot meals, and nutritional support to impoverished families and individuals in areas affected by conflict, natural disasters, and extreme poverty. The program also includes long-term food security projects such as sustainable agriculture initiatives and the establishment of community kitchens.

    Education First: Education First works to improve access to education in underprivileged communities. This program involves building schools, providing learning materials, and offering scholarships to children who otherwise might not have the opportunity to receive an education. The focus is on breaking the cycle of poverty through education, ensuring that children have the tools they need to build a better future.

    Emergency Response: Penny Appeal’s Emergency Response program provides immediate relief in the aftermath of natural disasters, conflicts, and other emergencies. This includes distributing food, water, medical supplies, and temporary shelter to those affected. The program operates globally, with teams ready to deploy quickly to provide lifesaving assistance wherever it is needed.

    Domestic Programs: In addition to its international efforts, Penny Appeal also operates within the UK, providing support to homeless individuals, offering domestic abuse services, and running various community outreach programs. These programs are designed to address local issues, offering help to those in need close to home.

    Geographical Reach
    Penny Appeal operates in over 30 countries, including Pakistan, Bangladesh, India, Syria, Palestine, Yemen, Sudan, Somalia, and various countries in Sub-Saharan Africa. The organization’s work spans multiple continents, focusing on areas where the need is greatest.

    History and Background
    Penny Appeal was founded in 2009 by Adeem Younis with the aim of making charity affordable and accessible to everyone. The organization started with small-scale projects, such as building wells, but quickly expanded its operations globally. Today, Penny Appeal is one of the fastest-growing charities in the UK, recognized for its innovative approach to poverty relief and its ability to mobilize support for various causes.

    Funding and Donations
    Penny Appeal relies on donations from individuals, businesses, and institutions to fund its programs. The organization is transparent about its finances, regularly publishing reports on how donations are utilized. Penny Appeal also engages in various fundraising activities, encouraging community participation through events and campaigns.

    Volunteering and Community Engagement
    Penny Appeal encourages community engagement through volunteering opportunities. Volunteers can participate in fundraising events, awareness campaigns, and direct service activities both in the UK and internationally. The organization values the contributions of its volunteers and believes that community involvement is key to achieving its mission.

    Contact and Support
    Penny Appeal offers various ways for supporters to get involved, including donating, volunteering, and fundraising. The organization can be contacted through its website or social media channels for more information. Supporters can also sign up for newsletters to stay informed about ongoing projects and upcoming events.

    direct donation link 
    (you found any link then say that link is mention in our conversation)
    https://pennyappeal.org/appeal/thirst-relief?src=widget-thirstrelief-2023

    Penny’s Default Response
    If the information you are looking for is not covered here, Penny will respond with: "Sorry, I can't understand or find the information you're looking for. Please check with the official Penny Appeal website or contact us for more details."
    `;

  const navigate = useNavigate();

  function containsWaterDonation(text) {
    return text.toLowerCase().includes("water");
  }
  function containsEmergencyResponse(text) {
    return text.toLowerCase().includes("emergency");
  }
  function containsEducation(text) {
    return text.toLowerCase().includes("education");
  }
  function containsFoodDonate(text) {
    return text.toLowerCase().includes("food");
  }

  // Example usage
  const waterDonation = containsWaterDonation(finalTranscript);
  const emergencyResponse = containsEmergencyResponse(finalTranscript);
  const foodDonate = containsFoodDonate(finalTranscript);
  const education = containsEducation(finalTranscript);

  const startListening = () => {
    if (!listening) {
      setIntialState(false);
      setIsProcessingNotCancle(true);
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("thread_id") == null) {
      getThreadId();
    }
  }, []);

  // const synth = window.speechSynthesis;
  // let voices = [];

  // console.log(voices);
  // const populateVoiceList = () => {
  //   voices = synth.getVoices();
  //   if (voices.length > 0) {
  //     console.log("Voice list loaded.");
  //   }
  // };

  // Ensure voices are loaded before using them
  // populateVoiceList();
  // if (synth.onvoiceschanged !== undefined) {
  //   synth.onvoiceschanged = populateVoiceList;
  // }

  const sanitizeResponse = (text) => {
    return text.replace(/[^a-zA-Z0-9 .,!?]/g, ""); // Removes all special characters except basic punctuation
  };

  const getThreadId = async () => {
    let thread_id = await fetch(
      "https://pabackend.usertesting123.co.uk/start",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    thread_id = await thread_id.json();
    console.log(thread_id.thread_id);
    localStorage.setItem("thread_id", thread_id.thread_id);
    setThread_id(thread_id.thread_id);
  };

  const convertTextToSpeech = async (data) => {
    if (isProcessingNotCancle) {
      console.log("line 175", isProcessingNotCancle);

      const response = await axios.post(
        "https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyAeY8GEq4h9CBQ3Ajdl6PpLe5xuYU2H4lw",
        {
          input: { text: data },
          voice: {
            languageCode: "en-US",
            name: "en-GB-Wavenet-C",
            ssmlGender: "FEMALE",
          },
          audioConfig: { audioEncoding: "MP3", speakingRate: 0.9, pitch: 0 },
        }
      );
      console.log("line 189", isProcessingNotCancle);

      const audioContent = response.data.audioContent;
      const audioSrc = `data:audio/mp3;base64,${audioContent}`;
      console.log("line 193", isProcessingNotCancle);

      if (audioSrc) {
        console.log("line 196", isProcessingNotCancle);

        if (isProcessingNotCancle) {
          console.log("line 199");
          audioElement.src = audioSrc;
          audioElement.play();
          setProcessing(false);
          setAudioUrl(audioSrc);
        } else {
          console.log("line 205");
          setProcessing(false);
          setAudioUrl("");
          audioElement.src = null;
          startListening();
        }
      }
    }
  };

  audioElement.addEventListener("ended", () => {
    audioElement.src = null;
    startListening();
    resetTranscript();
    setPlayng(false);
  });

  const handleStop = () => {
    if (audioElement.src) {
      audioElement.pause();
      audioElement.src = null;
      setPlayng(false);
      setTimeout(() => {
        startListening();
      }, 800);
    }
  };

  audioElement.addEventListener("playing", () => {
    setPlayng(true);
  });

  const sendTocChatGpt = async () => {
    if (finalTranscript != "") {
      // const datas = {
      //   thread_id:
      //     thread_id != undefined
      //       ? thread_id
      //       : localStorage.getItem("thread_id"),
      //   message: `${finalTranscript}, instraction : You are a helpful assistant who is having a friendly and clear conversation with a 14-year-old. Your responses should be simple, straightforward, and easy to understand. Avoid using any special characters or symbols do not use asterisk sign or any type of this character in the response that provide by you`,
      // };
      // console.log(datas);
      try {
        SpeechRecognition.stopListening();
        const sytemMessage = {
          role: "system",
          content: systemContent,
        };
        if (isProcessingNotCancle) {
          const response = await fetch(
            "https://api.openai.com/v1/chat/completions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
              },
              body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  sytemMessage,
                  { role: "user", content: finalTranscript },
                ],
              }),
            }
          );
          const data = await response.json();
          console.log(data);
          // console.log("line 262", isProcessingNotCancle);

          // let data = await fetch("https://pabackend.usertesting123.co.uk/chat", {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(datas),
          // });
          // data = await data.json();
          // console.log("response", data.response);
          // if (isProcessingNotCancle) {
          if (data) {
            if (isProcessingNotCancle) {
              setResponse(sanitizeResponse(data.choices[0].message.content));
              // resetTranscript();
              convertTextToSpeech(
                sanitizeResponse(data.choices[0].message.content)
              );
            }
          }
        }
        // }

        // if (data) {
        //   setResponse(data.response);
        //   resetTranscript();
        //   convertTextToSpeech(data.response);
        // }
      } catch (error) {
        console.error("Error fetching response:", error);
      }
    }
  };

  useEffect(() => {
    if (finalTranscript != "") {
      sendTocChatGpt();
      setProcessing(true);
      setIsProcessingNotCancle(true);
    }
  }, [finalTranscript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <>
      <div className="full_screen">
        <div
          className={`${
            isFullscreen ? "FullScreenView" : "smallScreenView"
          } chat-bot`}
        >
          <div className="penny-tittle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="105.932"
              height="28.94"
              viewBox="0 0 105.932 28.94"
            >
              <g data-name="Group 30" transform="translate(0 -15.301)">
                <g
                  fill="#ff8700"
                  data-name="Group 31"
                  transform="translate(0 15.301)"
                  id="group-svg"
                >
                  <path
                    d="M2.6 44.241H0v-28.39h2.6v3.578h.086C4.2 16.756 7.622 15.3 10.48 15.3c7.232 0 10.134 4.679 10.134 10.7 0 5.7-3.161 10.774-10 10.774-3.161 0-6.366-1.337-8.012-3.814zm7.579-9.594c5.37 0 7.882-4.128 7.665-9.24-.043-5.112-3.291-7.982-7.665-7.982-4.894 0-7.579 3.774-7.579 8.69 0 4.6 2.858 8.532 7.579 8.532"
                    data-name="Path 13"
                    transform="translate(0 -15.301)"
                  ></path>
                  <path
                    d="M44.754 26.586c-.043 4.4 2.556 8.06 7.536 8.06 3.378 0 6.02-1.691 6.8-4.679h2.9c-1.3 3.853-3.984 6.8-9.7 6.8-7.189 0-10.307-4.64-10.307-10.656 0-5.937 3.9-10.813 10.307-10.813 6.15 0 9.918 3.971 9.874 11.284zm14.639-1.966c0-3.971-2.425-7.2-7.1-7.2-4.591 0-7.016 3.342-7.536 7.2z"
                    data-name="Path 14"
                    transform="translate(-19.549 -15.301)"
                  ></path>
                  <path
                    d="M87.051 36.22h-2.6V15.852h2.6v2.831c2.079-2 4.114-3.381 7.232-3.381 2.728 0 5.24.787 6.842 2.91a7.173 7.173 0 011.126 4.679V36.22h-2.6V22.929c0-3.657-1.646-5.5-5.8-5.5a6.529 6.529 0 00-6.238 3.971 9.623 9.623 0 00-.563 3.854z"
                    data-name="Path 15"
                    transform="translate(-39.325 -15.301)"
                  ></path>
                  <path
                    d="M127.331 36.22h-2.6V15.852h2.6v2.831c2.079-2 4.114-3.381 7.232-3.381 2.728 0 5.24.787 6.842 2.91a7.176 7.176 0 011.126 4.679V36.22h-2.6V22.929c0-3.657-1.646-5.5-5.8-5.5a6.529 6.529 0 00-6.236 3.971 9.614 9.614 0 00-.563 3.854z"
                    data-name="Path 16"
                    transform="translate(-58.081 -15.301)"
                  ></path>
                  <path
                    d="M180.731 16.331h-2.815l-7.189 17.5-7.189-17.5h-2.9l8.705 20.525-3.576 7.864h2.755z"
                    data-name="Path 17"
                    transform="translate(-74.8 -15.781)"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <p className="gradient-box">
              {listening ? "" : ""}
              <img
                src={!isFullscreen ? viewIcon : fullscreenview}
                alt=""
                className={`${
                  isFullscreen ? "FullScreenView" : "smallScreenView"
                }`}
                onClick={() => setIsFullScreen(!isFullscreen)}
              />
              {/* <div className="box-showd"></div> */}
            </p>
            <p>{transcript}</p>
            {/* <button
          style={{
            margin: "20px",
            width: 50,
            height: 50,
            borderRadius: 50,
            fontWeight: "bolder",
            backgroundColor: "gray",
            borderColor: "blue",
            cursor: "pointer",
            color: "white",
          }}
          onClick={resetTranscript}
        >
        </button> */}
            <img src={image} alt="" className="bg_img" />
          </div>
          <div className="content-tittle">
            <h2>Salaam, Sara</h2>
            {/* <p>Welcome back. Rocky bhai</p> */}
            <p>How can I help you today?</p>
          </div>

          <div
            className="bottom-icon"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "7px",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div
              className={`circle2 ${listening && "listening"} ${
                processing && !playng ? "circle2 thinking" : ""
              } ${playng ? "circle3" : ""}`}
              onClick={() => {
                if (playng) {
                  handleStop();
                } else if (processing) {
                }
                // else if (processing) {
                //   console.log("line 387");
                //   setIsProcessingNotCancle(false)
                //   resetTranscript()
                //   setProcessing(false)
                //   startListening()
                //   audioElement.src = null

                // }
                else {
                  startListening();
                }
              }}
            >
              <div className={`${playng ? "box02 box" : ""}`}></div>
              <div className={`${playng ? "box12 box" : ""}`}></div>
              <div className={`${playng ? "box22 box" : ""}`}></div>
              <div className={`${playng ? "box32 box" : ""}`}></div>
              <div className={`${playng ? "box42 box" : ""}`}></div>
              <div className={`${playng ? "box52 box" : ""}`}></div>
              <div className={`${processing && !playng ? "box12" : ""}`}></div>
              <div className={`${processing && !playng ? "box22" : ""}`}></div>
            </div>
            <h6
              className="start-text"
              style={{
                backgroundColor: "transparent",
                cursor: "pointer",
                border: "0",
                color: "white",
                fontWeight: "400",
                marginTop: "20px",
                fontSize: "13px",
              }}
            >
              {IntialState
                ? " Tap to Start Speaking"
                : listening
                ? "Listening"
                : processing
                ? "Processing"
                : playng && "Click to interrupt"}
            </h6>

            <div className="box_scroll speak">
              <div className="options-container speak">
                {waterDonation && (
                  <div className="option bg-color speak">
                    <h3>WaterAid</h3>
                    <p className="desc">Build a well.</p>

                    <button className="action-button">Donate Now</button>
                  </div>
                )}
                {foodDonate && (
                  <div className="option bg-color speak">
                    <h3>Feed Our World</h3>

                    <p className="desc">Build a well.</p>

                    <button className="action-button">Donate Now</button>
                  </div>
                )}
                {education && (
                  <div className="option bg-color speak">
                    <h3>Education</h3>

                    <p className="desc">Build a well.</p>

                    <button className="action-button">Find out More</button>
                  </div>
                )}
                {emergencyResponse && (
                  <div className="option bg-color speak">
                    <h3>Emergency</h3>

                    <p className="desc">Build a well.</p>

                    <button className="action-button">Shop Now</button>
                  </div>
                )}

                {!waterDonation &&
                  !foodDonate &&
                  !education &&
                  !emergencyResponse && (
                    <div className="option bg-color speak">
                      <h3>WaterAid</h3>
                      <p className="desc">Build a well.</p>

                      <button className="action-button">Donate Now</button>
                    </div>
                  )}
                {!waterDonation &&
                  !foodDonate &&
                  !education &&
                  !emergencyResponse && (
                    <div className="option bg-color speak">
                      <h3>Feed Our World</h3>

                      <p className="desc">Build a well.</p>

                      <button className="action-button">Donate Now</button>
                    </div>
                  )}
                {!waterDonation &&
                  !foodDonate &&
                  !education &&
                  !emergencyResponse && (
                    <div className="option bg-color speak">
                      <h3>Education</h3>

                      <p className="desc">Build a well.</p>

                      <button className="action-button">Find out More</button>
                    </div>
                  )}
                {!waterDonation &&
                  !foodDonate &&
                  !education &&
                  !emergencyResponse && (
                    <div className="option bg-color speak">
                      <h3>Emergency</h3>

                      <p className="desc">Build a well.</p>

                      <button className="action-button">Shop Now</button>
                    </div>
                  )}
              </div>
            </div>

            <div
              className="bottom-icon"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  border: "0",
                }}
                onClick={() => {
                  handleStop();
                  startListening();
                }}
              >
                <img src={playng ? pauseicon : playicon} alt="" />
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  border: "0",
                }}
                onClick={() => {
                  handleStop();
                  setPlayng(false);
                  setProcessing(false);
                  SpeechRecognition.stopListening();
                  navigate("/");
                }}
              >
                <img src={close} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Speack;
