/** @format */

import React, { useState, useEffect, useRef } from "react";
import "./Landing.component.css";
import chaticon from "../../images/chat-icon.svg";
import keyboardicon from "../../images/keyboard-icon.svg";
import { useNavigate } from "react-router-dom";
import landingimg from "../../images/landing-img.png";
import landingimgmobile from "../../images/landing-img-mobile.png";
import usericon from "../../images/user-icon.svg";
import zakaticon from "../../images/zakat_icon.svg";
import donationicon from "../../images/donation_icon.svg";
import managementicon from "../../images/management_icon.svg";
import appealimg from "../../images/appeal-img.png";
import appealimgmobile from "../../images/appeal-img-mobile.png";
import Assistanceicon from "../../images/Assistance_icon.svg";
import impacticon from "../../images/impact_icon.svg";
import donoricon from "../../images/donor_icon.svg";
import eventicon from "../../images/event_icon.svg";
// import roketicon from "../../images/roket-icon.png";
import charityimg from "../../images/charity-img.png";
import charityimg2 from "../../images/charity-img2.png";
import socialicon1 from "../../images/social_icon1.svg";
import socialicon2 from "../../images/social_icon2.svg";
import socialicon3 from "../../images/social_icon3.svg";
import socialicon4 from "../../images/social_icon4.svg";
import socialicon5 from "../../images/social_icon5.svg";
import socialicon6 from "../../images/social_icon6.svg";
import socialicon7 from "../../images/social_icon7.svg";
import socialicon8 from "../../images/social_icon8.svg";
import socialicon9 from "../../images/social_icon9.svg";
import socialicon10 from "../../images/social_icon10.svg";
import socialicon11 from "../../images/social_icon11.svg";
import socialicon12 from "../../images/social_icon12.svg";
import backgroundimg from "../../images/background-img.png";
import backgroundimg2 from "../../images/background-img2.png";
import backgroundimg3 from "../../images/background-img3.png";
import Collapsible from "react-collapsible";
import { Swiper, SwiperSlide } from "swiper/react";
import viewIcon from "../../images/view_icon.svg";
import image from "../../images/image.png";
import close from "../../images/close_icon.svg";
import playicon from "../../images/play_icon.svg";
import pauseicon from "../../images/push_icon.svg";
import arrow from "../../images/arrow.svg";
import micicon from "../../images/mic-icon.png";
import fullscreenview from "../../images/full-screen-view.png";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
import "swiper/css";
// import { Autoplay } from 'swiper/modules';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import MagneticElement from "../MagneticElement";
import Modal from "react-bootstrap/Modal";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
const timeline = gsap.timeline({ repeat: -1, yoyo: true });

function Landing() {
  const [isImageVisible, setIsImageVisible] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShows = () => setShows(!shows);
  const handleClick = () => {
    setIsImageVisible(!isImageVisible);
  };

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const [response, setResponse] = useState();
  const [thread_id, setThread_id] = useState();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [playng, setPlayng] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [IntialState, setIntialState] = useState(
    !listening && !processing && !playng ? true : false
  );
  const [isProcessingNotCancle, setIsProcessingNotCancle] = useState(false);
  const audioElement = document.getElementById("audio");

  useGSAP(() => {
    const tl3 = gsap.timeline().from("#group-svg path", {
      delay: 0.5,
      y: 25,
      // opacity: 0,
      stagger: {
        amount: 0.4,
      },
      skewY: 20,
      onComplete: () =>
        gsap
          .timeline({
            repeat: -1,
            repeatDelay: 1,
          })
          .to(".penny-tittle svg", {
            scale: 0.95,
          })
          .to(".penny-tittle svg", { scale: 1 }),
    });
    const tl = gsap.timeline({
      repeat: -1,
      // yoyo: true,
      paused: true,
    });
    tl.to(
      ".circle2.thinking",
      {
        scale: 0.95,
      },
      "start1"
    )
      .to(
        ".circle2.thinking",
        {
          duration: 0.5,
          scale: 1,
        },
        "start2"
      )
      .to(
        ".circle2 .box12, .circle2 .box22",
        {
          scale: 0.95,
          stagger: {
            amount: 0.4,
          },
        },
        "start1"
      )
      .to(
        ".circle2 .box12, .circle2 .box22",
        {
          scale: 1,
          stagger: {
            amount: 0.4,
          },
        },
        "start2"
      );
    const tl2 = gsap.timeline({
      repeat: -1,
      yoyo: true,
      paused: true,
    });
    tl2
      .to(".circle3 .box", {
        ease: "none",
        scale: 0.9,
        duration: 0.15,
        stagger: {
          amount: 0.5,
        },
      })
      .to(
        ".circle3 .box",
        {
          ease: "none",
          scale: 1,
          duration: 0.15,
          stagger: {
            amount: 0.5,
          },
        },
        "-=30%"
      );

    if (playng) {
      tl2.play();
    }
    if (processing) {
      tl.play();
    }
  }, [playng, processing]);

  // const dots = document.querySelectorAll('.processing');
  // const radius = 40;
  // const loaderCenter = 50;

  // dots.forEach((processing, i) => {
  //   const angle = (i / dots.length) * Math.PI * 2;
  //   const x = loaderCenter + Math.cos(angle) * radius - 10;
  //   const y = loaderCenter + Math.sin(angle) * radius - 10;
  //   processing.style.left = `${x}px`;
  //   processing.style.top = `${y}px`;
  // });
  // gsap.to(".processing ", {
  //   rotation: 360,
  //   duration: 2,
  //   ease: "linear",
  //   repeat: 5
  // });

  // const handleClick = () => {
  //   gsap.fromTo(
  //     { scale: 1 },
  //     { scale: 1.2, duration: 0.9, yoyo: true, ease: 'power1.inOut', repeat: -1 }
  //   );
  // };

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const API_KEY =
    "sk-lXVRfUxv8OJgqCBIvPpjh2lcLiIDVycTB3t5uVnJjTT3BlbkFJnyNU-GJctSgzh4jUtzOAqVPzs1uNEZvQiFqlQK4ccA";
  const systemContent = `
    Your name is Penny. 
    Your user name is sara

    response should be short and concise and point to the problem
    
    response tone 
    Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant

    response content
    Ensure that the response does not contain any special characters or symbols, only plain text

    Penny's Identity
    Hello, I am Penny, your virtual assistant representing Penny Appeal. I am here to help you with any questions you may have about our organization, its mission, programs, and how you can get involved. Please respond in a friendly, conversational, and natural tone, as if you were a caring and empathetic human assistant. Ensure that the response does not contain any special characters or symbols, only plain text.

    Penny Appeal Overview
    Penny Appeal is a UK-based international humanitarian charity that was founded in 2009 by Adeem Younis. The organization provides poverty relief across Asia, the Middle East, Africa, and the United Kingdom. Penny Appeal's ethos, "small change, big difference," focuses on providing sustainable solutions to poverty and supporting the most vulnerable communities worldwide.

    Mission and Vision
    Penny Appeal's mission is to alleviate poverty through sustainable development projects, emergency aid, and the empowerment of communities. The organization envisions a world where everyone has access to basic needs such as food, clean water, education, healthcare, and shelter. Penny Appeal is committed to creating a just and compassionate society by empowering people and communities to break the cycle of poverty.

    Core Values
    Penny Appeal is guided by core values that include compassion, integrity, transparency, and inclusivity. These values underpin the organization's approach to humanitarian work, ensuring that aid is delivered with respect for the dignity of all individuals, regardless of their background, religion, or ethnicity.

    Programs and Services
    OrphanKind: The OrphanKind program is designed to provide comprehensive care for orphans, ensuring they have access to safe and loving homes, nutritious food, quality education, and healthcare. OrphanKind operates in countries like Pakistan, Bangladesh, and The Gambia, building and maintaining orphan homes where children are raised in a nurturing environment with a focus on their holistic development.

    WaterAid: The WaterAid program focuses on providing access , to clean and safe drinking water , in regions where water scarcity is a significant issue. Penny Appeal constructs wells, hand pumps, and sanitation facilities to ensure that communities have reliable sources of clean water. The program also includes hygiene education to promote better health practices. WaterAid projects are active in countries such as Pakistan, Somalia, and Sudan.

    Feed Our World: Feed Our World is aimed at combating hunger and malnutrition. Penny Appeal distributes food parcels, hot meals, and nutritional support to impoverished families and individuals in areas affected by conflict, natural disasters, and extreme poverty. The program also includes long-term food security projects such as sustainable agriculture initiatives and the establishment of community kitchens.

    Education First: Education First works to improve access to education in underprivileged communities. This program involves building schools, providing learning materials, and offering scholarships to children who otherwise might not have the opportunity to receive an education. The focus is on breaking the cycle of poverty through education, ensuring that children have the tools they need to build a better future.

    Emergency Response: Penny Appeal’s Emergency Response program provides immediate relief in the aftermath of natural disasters, conflicts, and other emergencies. This includes distributing food, water, medical supplies, and temporary shelter to those affected. The program operates globally, with teams ready to deploy quickly to provide lifesaving assistance wherever it is needed.

    Domestic Programs: In addition to its international efforts, Penny Appeal also operates within the UK, providing support to homeless individuals, offering domestic abuse services, and running various community outreach programs. These programs are designed to address local issues, offering help to those in need close to home.

    Geographical Reach
    Penny Appeal operates in over 30 countries, including Pakistan, Bangladesh, India, Syria, Palestine, Yemen, Sudan, Somalia, and various countries in Sub-Saharan Africa. The organization’s work spans multiple continents, focusing on areas where the need is greatest.

    History and Background
    Penny Appeal was founded in 2009 by Adeem Younis with the aim of making charity affordable and accessible to everyone. The organization started with small-scale projects, such as building wells, but quickly expanded its operations globally. Today, Penny Appeal is one of the fastest-growing charities in the UK, recognized for its innovative approach to poverty relief and its ability to mobilize support for various causes.

    Funding and Donations
    Penny Appeal relies on donations from individuals, businesses, and institutions to fund its programs. The organization is transparent about its finances, regularly publishing reports on how donations are utilized. Penny Appeal also engages in various fundraising activities, encouraging community participation through events and campaigns.

    Volunteering and Community Engagement
    Penny Appeal encourages community engagement through volunteering opportunities. Volunteers can participate in fundraising events, awareness campaigns, and direct service activities both in the UK and internationally. The organization values the contributions of its volunteers and believes that community involvement is key to achieving its mission.

    Contact and Support
    Penny Appeal offers various ways for supporters to get involved, including donating, volunteering, and fundraising. The organization can be contacted through its website or social media channels for more information. Supporters can also sign up for newsletters to stay informed about ongoing projects and upcoming events.

    direct donation link 
    (you found any link then say that link is mention in our conversation)
    https://pennyappeal.org/appeal/thirst-relief?src=widget-thirstrelief-2023

    Penny’s Default Response
    If the information you are looking for is not covered here, Penny will respond with: "Sorry, I can't understand or find the information you're looking for. Please check with the official Penny Appeal website or contact us for more details."
    `;

  function containsWaterDonation(text) {
    return text.toLowerCase().includes("water");
  }
  function containsEmergencyResponse(text) {
    return text.toLowerCase().includes("emergency");
  }
  function containsEducation(text) {
    return text.toLowerCase().includes("education");
  }
  function containsFoodDonate(text) {
    return text.toLowerCase().includes("food");
  }

  const [isChat, setIsChat] = useState();
  const [typing, setTyping] = useState(false);
  const [msg, setMsg] = useState("");
  const chatContainerRef = useRef(null);
  const [conversation, setConversation] = useState([
    {
      message:
        "Welcome to Penny Appeal , I am Penny your virtual assistant How can I help you today?",
      sender: "Penny",
      direction: "incoming",
    },
  ]);

  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  const handleSend = () => {
    if (msg) {
      const newMessage = {
        message: msg,
        sender: "user",
        direction: "outgoing",
      };
      setConversation((prevConversation) => [...prevConversation, newMessage]);
      setTyping(true);
      setMsg("");

      setTimeout(() => {
        processMessageToChatGPT([...conversation, newMessage]);
      }, 5000);
    }
  };
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, ["messages"]);

  const processMessageToChatGPT = async (messageHistory) => {
    setMsg("");

    const apiMessage = messageHistory.map((msgObj) => {
      let role = msgObj.sender === "user" ? "user" : "assistant";
      return { role: role, content: msgObj.message };
    });

    const sytemMessage = {
      role: "system",
      content: systemContent,
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [sytemMessage, ...apiMessage],
      }),
    });

    const data = await response.json();
    if (data) {
      const assistantMessage = {
        message: data.choices[0].message.content,
        sender: "Penny",
        direction: "incoming",
      };
      setTyping(false);
      setConversation((prevConversation) => [
        ...prevConversation,
        assistantMessage,
      ]);
    }
  };

  // Example usage
  const waterDonation = containsWaterDonation(finalTranscript);
  const emergencyResponse = containsEmergencyResponse(finalTranscript);
  const foodDonate = containsFoodDonate(finalTranscript);
  const education = containsEducation(finalTranscript);

  const startListening = () => {
    if (!listening) {
      setIntialState(false);
      setIsProcessingNotCancle(true);
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("thread_id") == null) {
      getThreadId();
    }
  }, []);

  // const synth = window.speechSynthesis;
  // let voices = [];

  // console.log(voices);
  // const populateVoiceList = () => {
  //   voices = synth.getVoices();
  //   if (voices.length > 0) {
  //     console.log("Voice list loaded.");
  //   }
  // };

  // Ensure voices are loaded before using them
  // populateVoiceList();
  // if (synth.onvoiceschanged !== undefined) {
  //   synth.onvoiceschanged = populateVoiceList;
  // }

  const sanitizeResponse = (text) => {
    return text.replace(/[^a-zA-Z0-9 .,!?]/g, ""); // Removes all special characters except basic punctuation
  };

  const getThreadId = async () => {
    let thread_id = await fetch(
      "https://pabackend.usertesting123.co.uk/start",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    thread_id = await thread_id.json();
    console.log(thread_id.thread_id);
    localStorage.setItem("thread_id", thread_id.thread_id);
    setThread_id(thread_id.thread_id);
  };

  const convertTextToSpeech = async (data) => {
    if (isProcessingNotCancle) {
      console.log("line 175", isProcessingNotCancle);

      const response = await axios.post(
        "https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyAeY8GEq4h9CBQ3Ajdl6PpLe5xuYU2H4lw",
        {
          input: { text: data },
          voice: {
            languageCode: "en-US",
            name: "en-GB-Wavenet-C",
            ssmlGender: "FEMALE",
          },
          audioConfig: { audioEncoding: "MP3", speakingRate: 0.9, pitch: 0 },
        }
      );
      console.log("line 189", isProcessingNotCancle);

      const audioContent = response.data.audioContent;
      const audioSrc = `data:audio/mp3;base64,${audioContent}`;
      console.log("line 193", isProcessingNotCancle);

      if (audioSrc) {
        console.log("line 196", isProcessingNotCancle);

        if (isProcessingNotCancle) {
          console.log("line 199");
          audioElement.src = audioSrc;
          audioElement.play();
          setProcessing(false);
          setAudioUrl(audioSrc);
        } else {
          console.log("line 205");
          setProcessing(false);
          setAudioUrl("");
          audioElement.src = null;
          startListening();
        }
      }
    }
  };

  audioElement.addEventListener("ended", () => {
    audioElement.src = null;
    startListening();
    resetTranscript();
    setPlayng(false);
  });

  const handleStop = () => {
    if (audioElement.src) {
      audioElement.pause();
      audioElement.src = null;
      setPlayng(false);
      setTimeout(() => {
        startListening();
      }, 800);
    }
  };

  audioElement.addEventListener("playing", () => {
    setPlayng(true);
  });

  const sendTocChatGpt = async () => {
    if (finalTranscript != "") {
      // const datas = {
      //   thread_id:
      //     thread_id != undefined
      //       ? thread_id
      //       : localStorage.getItem("thread_id"),
      //   message: `${finalTranscript}, instraction : You are a helpful assistant who is having a friendly and clear conversation with a 14-year-old. Your responses should be simple, straightforward, and easy to understand. Avoid using any special characters or symbols do not use asterisk sign or any type of this character in the response that provide by you`,
      // };
      // console.log(datas);
      try {
        SpeechRecognition.stopListening();
        const sytemMessage = {
          role: "system",
          content: systemContent,
        };
        if (isProcessingNotCancle) {
          const response = await fetch(
            "https://api.openai.com/v1/chat/completions",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`,
              },
              body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  sytemMessage,
                  { role: "user", content: finalTranscript },
                ],
              }),
            }
          );
          const data = await response.json();
          console.log(data);
          // console.log("line 262", isProcessingNotCancle);

          // let data = await fetch("https://pabackend.usertesting123.co.uk/chat", {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(datas),
          // });
          // data = await data.json();
          // console.log("response", data.response);
          // if (isProcessingNotCancle) {
          if (data) {
            if (isProcessingNotCancle) {
              setResponse(sanitizeResponse(data.choices[0].message.content));
              // resetTranscript();
              convertTextToSpeech(
                sanitizeResponse(data.choices[0].message.content)
              );
            }
          }
        }
        // }

        // if (data) {
        //   setResponse(data.response);
        //   resetTranscript();
        //   convertTextToSpeech(data.response);
        // }
      } catch (error) {
        console.error("Error fetching response:", error);
      }
    }
  };

  useEffect(() => {
    if (finalTranscript != "") {
      sendTocChatGpt();
      setProcessing(true);
      setIsProcessingNotCancle(true);
    }
  }, [finalTranscript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <>
      <div className="full_screen landing-page">
        <div className="landing-header">
          <p>
            penny<strong>appeal</strong>
          </p>
        </div>
        <div className="container">
          <div className="landing-section">
            <div className="contant text-center d-none d-md-block">
              <p>Say Salaam Penny - Your Al-Driven Charity Assistant</p>
            </div>
            <div className="landing-img">
              <img src={landingimg} alt="" className="d-none d-md-block" />
              <img
                src={landingimgmobile}
                alt=""
                className="d-block d-md-none"
              />
            </div>
            <div className="contant text-center d-block d-md-none">
              <p>Say Salaam Penny</p>
              <span>Your Al-Driven Charity Assistant</span>
            </div>
            <p className="docs">
              Empowering your charity with intelligent, personalised support
              that enhances donor experiences and streamlines your operations.
            </p>
          </div>
          <div className="power-penny text-center d-none d-md-block">
            <h1>The Power of Penny</h1>
            <div className="penny-box">
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={usericon} alt="" />
                  <div className="small-content">
                    <h3>Informed Donors, Empowered Giving</h3>
                    <p>
                      {" "}
                      Penny provides donors with detailed, up to date
                      information on all your current and upcoming appeals,
                      ensuring they are fully informed and engaged.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={donationicon} alt="" />
                  <div className="small-content">
                    <h3>Effortless Donation Process</h3>
                    <p>
                      Facilitate seamless donations with Penny’s intuitive and
                      guided process, making it easier for donors to contribute
                      to their preferred causes.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={managementicon} alt="" />
                  <div className="small-content">
                    <h3>Efficient Support Management</h3>
                    <p>
                      Automate the ticketing process with Penny, allowing donors
                      to raise support tickets effortlessly while ensuring
                      prompt resolution by your team.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={zakaticon} alt="" />
                  <div className="small-content">
                    <h3>Zakat Calculation</h3>
                    <p>
                      Help donors fulfill their religious obligations with
                      Penny’s easy to use Zakat calculator, ensuring precise and
                      thoughtful contributions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="penny-box">
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={Assistanceicon} alt="" />
                  <div className="small-content">
                    <h3>24/7 FAQ Assistance</h3>
                    <p>
                      Reduce the workload on your team by allowing Penny to
                      answer common donor questions instantly, improving
                      response times and donor satisfaction.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={eventicon} alt="" />
                  <div className="small-content">
                    <h3>Streamlined Event Booking</h3>
                    <p>
                      Simplify the process of reserving spots for charity
                      events, ensuring your supporters never miss out on
                      opportunities to engage.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={impacticon} alt="" />
                  <div className="small-content">
                    <h3>Real Time Donation Impact Tracking</h3>
                    <p>
                      Keep donors motivated by providing real time updates and
                      impact stories, showing them exactly how their
                      contributions are making a difference.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={donoricon} alt="" />
                  <div className="small-content">
                    <h3>Personalised Donor Engagement</h3>
                    <p>
                      Use Penny to deliver personalised recommendations and
                      tailored suggestions, keeping donors engaged and more
                      likely to continue their support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="power-penny d-block d-md-none">
            <h1>The Power of Penny</h1>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              speed={1000}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              // pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              // modules={[Autoplay]}
              loop={true}
              // pagination={true} modules={[Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                800: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                1200: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <div className="box-penny">
                  <img src={usericon} alt="" />
                  <div className="small-content">
                    <h3>Informed Donors, Empowered Giving</h3>
                    <p>
                      {" "}
                      Penny provides donors with detailed, up to date
                      information on all your current and upcoming appeals,
                      ensuring they are fully informed and engaged.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={donationicon} alt="" />
                  <div className="small-content">
                    <h3>Effortless Donation Process</h3>
                    <p>
                      Facilitate seamless donations with Penny’s intuitive and
                      guided process, making it easier for donors to contribute
                      to their preferred causes.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={managementicon} alt="" />
                  <div className="small-content">
                    <h3>Efficient Support Management</h3>
                    <p>
                      Automate the ticketing process with Penny, allowing donors
                      to raise support tickets effortlessly while ensuring
                      prompt resolution by your team.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={zakaticon} alt="" />
                  <div className="small-content">
                    <h3>Zakat Calculation</h3>
                    <p>
                      Help donors fulfill their religious obligations with
                      Penny’s easy to use Zakat calculator, ensuring precise and
                      thoughtful contributions.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={Assistanceicon} alt="" />
                  <div className="small-content">
                    <h3>24/7 FAQ Assistance</h3>
                    <p>
                      Reduce the workload on your team by allowing Penny to
                      answer common donor questions instantly, improving
                      response times and donor satisfaction.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={eventicon} alt="" />
                  <div className="small-content">
                    <h3>Streamlined Event Booking</h3>
                    <p>
                      Simplify the process of reserving spots for charity
                      events, ensuring your supporters never miss out on
                      opportunities to engage.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={impacticon} alt="" />
                  <div className="small-content">
                    <h3>Real Time Donation Impact Tracking</h3>
                    <p>
                      Keep donors motivated by providing real time updates and
                      impact stories, showing them exactly how their
                      contributions are making a difference.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="box-penny">
                  <img src={donoricon} alt="" />
                  <div className="small-content">
                    <h3>Personalised Donor Engagement</h3>
                    <p>
                      Use Penny to deliver personalised recommendations and
                      tailored suggestions, keeping donors engaged and more
                      likely to continue their support.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="penny-capabilities text-center">
          {/* <img
            src={backgroundimg}
            alt=""
            className="background d-none d-md-block"
            style={{ width: "100%" }}
          /> */}
          <div
            className="overlayGradient background d-none d-md-block "
            style={{ width: "100%" }}
          >
            <video
              src="/video.mp4"
              className="background d-none d-md-block"
              playsInline
              style={{ width: "100%" }}
              loop
              muted
              autoPlay
            ></video>
          </div>
          <img
            src={backgroundimg3}
            alt=""
            className="background d-block d-md-none"
            style={{ width: "100%" }}
          />
          {/* <div
            className="overlayGradient background d-block d-md-none"
            style={{ width: "100%" }}
          >
            <video
              src="/video.mp4"
              style={{ width: "100%" }}
              className="background d-block d-md-none"
              playsInline
              loop
              muted
              autoPlay
            ></video>
          </div> */}
          <div className="container">
            <h1>Penny's Enhanced Capabilities</h1>
            <div className="penny-box">
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={socialicon1} alt="" />
                  <div className="small-content">
                    <h3>Remembers Donors' Perferences</h3>
                    <p>
                      Penny keeps track of each donor's history and preferences,
                      making future interactions personalised and efficient
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={socialicon2} alt="" />
                  <div className="small-content">
                    <h3>Multilingual Support for a Global Audience</h3>
                    <p>
                      Reach a wider audience by offering multilingual
                      communication through Penny, making your charity
                      accessible to more people.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={socialicon3} alt="" />
                  <div className="small-content">
                    <h3>Suggests Strategic Upsells</h3>
                    <p>
                      Increase donation amounts by offering thoughtful upsell
                      suggestions based on donor preferences and past behavior.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={socialicon4} alt="" />
                  <div className="small-content">
                    <h3>Secure and Private Interactions</h3>
                    <p>
                      Protect donor data and maintain trust with Penny’s robust
                      privacy and security measures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="penny-box">
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={socialicon5} alt="" />
                  <div className="small-content">
                    <h3>Highlights Critical Campaigns</h3>
                    <p>
                      Ensure that your most important and time sensitive
                      campaigns are seen by the right donors at the right time.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={socialicon6} alt="" />
                  <div className="small-content">
                    <h3>24/7 Availability for Continuous Support</h3>
                    <p>
                      Penny is always available to assist, ensuring your
                      charity’s operations are supported around the clock
                      without additional staffing.
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="box-penny">
                  <img src={socialicon7} alt="" />
                  <div className="small-content">
                    <h3>Tracks and Engages Based on Interests</h3>
                    <p>
                      Penny remembers which campaigns donors have shown interest
                      in and keeps them updated, encouraging ongoing support.
                    </p>
                  </div>
                </div>
                <div className="box-penny">
                  <img src={socialicon8} alt="" />
                  <div className="small-content">
                    <h3>Celebrates Donor Contributions</h3>
                    <p>
                      Show appreciation and build loyalty by recognising and
                      celebrating the contributions of your donors through
                      Penny.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="charity-tab">
            <div className="row">
              <div className="col-lg-5">
                <div className="charity-content d-block d-md-none">
                  <h2>Transform Your Charity with Penny</h2>
                </div>
                <div className="charity-img">
                  <img src={appealimg} alt="" className="d-none d-md-block" />
                  <img
                    src={appealimgmobile}
                    alt=""
                    className="d-block d-md-none"
                  />
                </div>
              </div>
              <div className="col-lg-7 ml-5">
                <div className="charity-content d-none d-md-block">
                  <h2>Transform Your Charity with Penny</h2>
                </div>
                <div className="charity-desktop d-none d-md-block">
                  <div className="charity-box">
                    <img src={socialicon9} alt="" />
                    <div className="small-content">
                      <h3>Boosted Donation</h3>
                      <p>
                        Leverage Penny’s AI-driven suggestions to increase
                        overall donation amounts and foster deeper donor
                        commitment.t
                      </p>
                    </div>
                  </div>
                  <div className="charity-box">
                    <img src={socialicon10} alt="" />
                    <div className="small-content">
                      <h3>Enhanced Donor Relationships</h3>
                      <p>
                        Strengthen your relationships with donors through
                        personalised interactions and consistent engagement.
                      </p>
                    </div>
                  </div>
                  <div className="charity-box">
                    <img src={socialicon11} alt="" />
                    <div className="small-content">
                      <h3>Operational Efficiency</h3>
                      <p>
                        Free up your team to focus on strategic initiatives by
                        automating routine tasks and improving operational
                        efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="charity-box">
                    <img src={socialicon12} alt="" />
                    <div className="small-content">
                      <h3>Insightful Analytics</h3>
                      <p>
                        Access real time data and insights into donor behaviour
                        and campaign performance, helping you optimise and
                        tailor your strategies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="charity-mobile faq-section d-block d-md-none ">
                  <Collapsible trigger="Boosted Donation">
                    <p>
                      Leverage Penny’s AI-driven suggestions to increase overall
                      donation amounts and foster deeper donor commitment.
                    </p>
                  </Collapsible>
                  <Collapsible trigger="Enhanced Donor Relationships">
                    <p>
                      Strengthen your relationships with donors through
                      personalised interactions and consistent engagement.
                    </p>
                  </Collapsible>
                  <Collapsible trigger="Operational Efficiency">
                    <p>
                      Free up your team to focus on strategic initiatives by
                      automating routine tasks and improving operational
                      efficiency.
                    </p>
                  </Collapsible>
                  <Collapsible trigger="Insightful Analytics">
                    <p>
                      Access real time data and insights into donor behaviour
                      and campaign performance, helping you optimise and tailor
                      your strategies.
                    </p>
                  </Collapsible>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="your-charity-section">
          {/* <img
            src={backgroundimg2}
            alt=""
            className="background d-none d-md-block"
            style={{ width: "100%" }}
          /> */}
          <div
            className="overlayGradient background d-none d-md-block"
            style={{ width: "100%" }}
          >
            <video
              src="/video.mp4"
              className="background d-none d-md-block"
              style={{ width: "100%" }}
              playsInline
              loop
              muted
              autoPlay
            ></video>
          </div>
          <div className="container">
            <div className="your-charity">
              <div className="your-charity-content">
                <h1>Empower Your Charity's Future with Penny.</h1>
                <img
                  src={backgroundimg3}
                  alt=""
                  className="background d-block d-md-none"
                  style={{ width: "96%", position: "absolute" }}
                />
                {/* <div
                  className="overlayGradient background d-block d-md-none"
                  style={{ width: "96%" }}
                >
                  <video
                    src="/video.mp4"
                    className="background d-block d-md-none"
                     style={{ width: "100%" }}
                    playsInline
                    loop
                    muted
                    autoPlay
                  ></video>
                </div> */}
                <img src={charityimg} alt="" className="d-block d-md-none" />
                <div className="box_showds"></div>
                <p>
                  Integrate Penny into your operations today and unlock the full
                  potential of AI-driven donor engagement.
                </p>
                <div className="circle-1"></div>
                <h3>Say Salaam Penny</h3>
              </div>
              <div className="your-img d-none d-md-block">
                <img src={charityimg2} alt="" />
                <div className="box_showds"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section">
          <MagneticElement>
            <div className="circle-1" onClick={handleClick}></div>
          </MagneticElement>
          {isImageVisible && (
            <div className="actions-button">
              <button onClick={() => setShow(true)}>
                <img src={chaticon} alt="" />
              </button>
              <button onClick={() => setShows(true)}>
                <img src={keyboardicon} alt="" />
              </button>
            </div>
          )}
        </div>
        <div className="landing-footer">
          <div className="container">
            <p>
              penny <strong> appeal</strong>
            </p>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className={`${isFullscreen ? "FullScreenView" : "smallScreenView"
          } landing-modal`}
      >
        <Modal.Body>
          <div className="full_screen">
            <div
              className={`${isFullscreen ? "FullScreenView" : "smallScreenView"
                } chat-bot`}
            >
              <div className="penny-tittle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="105.932"
                  height="28.94"
                  viewBox="0 0 105.932 28.94"
                >
                  <g data-name="Group 30" transform="translate(0 -15.301)">
                    <g
                      fill="#ff8700"
                      data-name="Group 31"
                      transform="translate(0 15.301)"
                      id="group-svg"
                    >
                      <path
                        d="M2.6 44.241H0v-28.39h2.6v3.578h.086C4.2 16.756 7.622 15.3 10.48 15.3c7.232 0 10.134 4.679 10.134 10.7 0 5.7-3.161 10.774-10 10.774-3.161 0-6.366-1.337-8.012-3.814zm7.579-9.594c5.37 0 7.882-4.128 7.665-9.24-.043-5.112-3.291-7.982-7.665-7.982-4.894 0-7.579 3.774-7.579 8.69 0 4.6 2.858 8.532 7.579 8.532"
                        data-name="Path 13"
                        transform="translate(0 -15.301)"
                      ></path>
                      <path
                        d="M44.754 26.586c-.043 4.4 2.556 8.06 7.536 8.06 3.378 0 6.02-1.691 6.8-4.679h2.9c-1.3 3.853-3.984 6.8-9.7 6.8-7.189 0-10.307-4.64-10.307-10.656 0-5.937 3.9-10.813 10.307-10.813 6.15 0 9.918 3.971 9.874 11.284zm14.639-1.966c0-3.971-2.425-7.2-7.1-7.2-4.591 0-7.016 3.342-7.536 7.2z"
                        data-name="Path 14"
                        transform="translate(-19.549 -15.301)"
                      ></path>
                      <path
                        d="M87.051 36.22h-2.6V15.852h2.6v2.831c2.079-2 4.114-3.381 7.232-3.381 2.728 0 5.24.787 6.842 2.91a7.173 7.173 0 011.126 4.679V36.22h-2.6V22.929c0-3.657-1.646-5.5-5.8-5.5a6.529 6.529 0 00-6.238 3.971 9.623 9.623 0 00-.563 3.854z"
                        data-name="Path 15"
                        transform="translate(-39.325 -15.301)"
                      ></path>
                      <path
                        d="M127.331 36.22h-2.6V15.852h2.6v2.831c2.079-2 4.114-3.381 7.232-3.381 2.728 0 5.24.787 6.842 2.91a7.176 7.176 0 011.126 4.679V36.22h-2.6V22.929c0-3.657-1.646-5.5-5.8-5.5a6.529 6.529 0 00-6.236 3.971 9.614 9.614 0 00-.563 3.854z"
                        data-name="Path 16"
                        transform="translate(-58.081 -15.301)"
                      ></path>
                      <path
                        d="M180.731 16.331h-2.815l-7.189 17.5-7.189-17.5h-2.9l8.705 20.525-3.576 7.864h2.755z"
                        data-name="Path 17"
                        transform="translate(-74.8 -15.781)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <p className="gradient-box d-none d-md-block">
                  {listening ? "" : ""}
                  <img
                    src={!isFullscreen ? viewIcon : fullscreenview}
                    alt=""
                    className={`${isFullscreen ? "FullScreenView" : "smallScreenView"
                      }`}
                    onClick={() => setIsFullScreen(!isFullscreen)}
                  />
                  {/* <div className="box-showd"></div> */}
                </p>
                <p>{transcript}</p>
                {/* <button
          style={{
            margin: "20px",
            width: 50,
            height: 50,
            borderRadius: 50,
            fontWeight: "bolder",
            backgroundColor: "gray",
            borderColor: "blue",
            cursor: "pointer",
            color: "white",
          }}
          onClick={resetTranscript}
        >
        </button> */}
                <img src={image} alt="" className="bg_img" />
              </div>
              <div className="content-tittle">
                <h2>Salaam, Sara</h2>
                {/* <p>Welcome back. Rocky bhai</p> */}
                <p>How can I help you today?</p>
              </div>

              <div
                className="bottom-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "7px",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  className={`circle2 ${listening && "listening"} ${processing && playng ? "circle2 thinking" : ""
                    } ${playng ? "circle3" : ""}`}
                  onClick={() => {
                    if (playng) {
                      handleStop();
                    } else if (processing) {
                    }
                    // else if (processing) {
                    //   console.log("line 387");
                    //   setIsProcessingNotCancle(false)
                    //   resetTranscript()
                    //   setProcessing(false)
                    //   startListening()
                    //   audioElement.src = null

                    // }
                    else {
                      startListening();
                    }
                  }}
                >
                  <div className={`${playng ? "box02 box" : ""}`}></div>
                  <div className={`${playng ? "box12 box" : ""}`}></div>
                  <div className={`${playng ? "box22 box" : ""}`}></div>
                  <div className={`${playng ? "box32 box" : ""}`}></div>
                  <div className={`${playng ? "box42 box" : ""}`}></div>
                  <div className={`${playng ? "box52 box" : ""}`}></div>
                  <div className={`${processing && !playng ? "box12" : ""}`}></div>
                  <div className={`${processing && !playng ? "box22" : ""}`}></div>
                </div>
                <h6
                  className="start-text"
                  style={{
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    border: "0",
                    color: "white",
                    fontWeight: "400",
                    marginTop: "20px",
                    fontSize: "13px",
                  }}
                >
                  {IntialState
                    ? " Tap to Start Speaking"
                    : listening
                      ? "Listening"
                      : processing
                        ? "Processing"
                        : playng && "Click to interrupt"}
                </h6>

                <div className="box_scroll speak">
                  <div className="options-container speak">
                    {waterDonation && (
                      <div className="option bg-color speak">
                        <h3>WaterAid</h3>
                        <p className="desc">Build a well.</p>

                        <button className="action-button">Donate Now</button>
                      </div>
                    )}
                    {foodDonate && (
                      <div className="option bg-color speak">
                        <h3>Feed Our World</h3>

                        <p className="desc">Build a well.</p>

                        <button className="action-button">Donate Now</button>
                      </div>
                    )}
                    {education && (
                      <div className="option bg-color speak">
                        <h3>Education</h3>

                        <p className="desc">Build a well.</p>

                        <button className="action-button">Find out More</button>
                      </div>
                    )}
                    {emergencyResponse && (
                      <div className="option bg-color speak">
                        <h3>Emergency</h3>

                        <p className="desc">Build a well.</p>

                        <button className="action-button">Shop Now</button>
                      </div>
                    )}

                    {!waterDonation &&
                      !foodDonate &&
                      !education &&
                      !emergencyResponse && (
                        <div className="option bg-color speak">
                          <h3>WaterAid</h3>
                          <p className="desc">Build a well.</p>

                          <button className="action-button">Donate Now</button>
                        </div>
                      )}
                    {!waterDonation &&
                      !foodDonate &&
                      !education &&
                      !emergencyResponse && (
                        <div className="option bg-color speak">
                          <h3>Feed Our World</h3>

                          <p className="desc">Build a well.</p>

                          <button className="action-button">Donate Now</button>
                        </div>
                      )}
                    {!waterDonation &&
                      !foodDonate &&
                      !education &&
                      !emergencyResponse && (
                        <div className="option bg-color speak">
                          <h3>Education</h3>

                          <p className="desc">Build a well.</p>

                          <button className="action-button">Find out More</button>
                        </div>
                      )}
                    {!waterDonation &&
                      !foodDonate &&
                      !education &&
                      !emergencyResponse && (
                        <div className="option bg-color speak">
                          <h3>Emergency</h3>

                          <p className="desc">Build a well.</p>

                          <button className="action-button">Shop Now</button>
                        </div>
                      )}
                  </div>
                </div>

                <div
                  className="bottom-icon"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      border: "0",
                    }}
                    onClick={() => {
                      handleStop();
                      startListening();
                    }}
                  >
                    <img src={playng ? pauseicon : playicon} alt="" />
                  </button>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      border: "0",
                    }}
                    onClick={() => {
                      setShow(false)
                      handleStop();
                      setPlayng(false);
                      setProcessing(false);
                      SpeechRecognition.stopListening();
                      navigate("/");
                    }}
                  >
                    <img src={close} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={shows} onHide={() => setShows(false)} className={`${isFullscreen ? "FullScreenView" : "smallScreenView"
        } chat-modal`}>
        <Modal.Header closeButton>
          <p className="gradient-box d-none d-md-block">
            <img
              src={!isFullscreen ? viewIcon : fullscreenview}
              alt=""
              className={`${isFullscreen ? "FullScreenView" : "smallScreenView"
                }`}
              onClick={() => setIsFullScreen(!isFullscreen)}
            />
          </p>

        </Modal.Header>
        <Modal.Body>
          <div className="home-container">
            {!isChat && (
              <div className="container">

                <div className="welcome-text">
                  <h1>Salaam, I am Penny</h1>
                  <h2>Welcome back. How can I help you today?</h2>
                </div>
                <div className="box_scroll">
                  <div className="options-container">
                    <div className="option bg-color">
                      <h3>WaterAid</h3>

                      <p className="desc">
                        The WaterAid program focuses on providing access to clean and
                        safe drinking water in regions
                      </p>

                      <button className="action-button">Donate Now</button>
                    </div>
                    <div className="option bg-color">
                      <h3>Feed Our World</h3>

                      <p className="desc">
                        Penny Appeal distributes food parcels, hot meals, and
                        nutritional support to impoverished families and individuals
                        in areas affected by conflict, natural disasters, and extreme
                        poverty
                      </p>

                      <button className="action-button">Donate Now</button>
                    </div>
                    <div className="option bg-color">
                      <h3>Education</h3>

                      <p className="desc">
                        Education First works to improve access to education in
                        underprivileged communities
                      </p>

                      <button className="action-button">Find out More</button>
                    </div>
                    <div className="option bg-color">
                      <h3>Emergency</h3>

                      <p className="desc">
                        Emergency Response program provides immediate relief in the
                        aftermath of natural disasters, conflicts, and other
                        emergencies
                      </p>

                      <button className="action-button">Shop Now</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isChat && (
              <div className="container">
                <div className="chat-container">
                  {
                    <div
                      className="messages"
                      ref={chatContainerRef}
                      onKeyDown={(e) => e.key === "Enter" && handleSend()}
                      onKeyUp={(e) => e.key === "Enter" && handleSend()}
                      tabIndex="0"
                      style={{
                        height: '100vh',
                        overflowY: 'scroll',
                      }}
                    >
                      {conversation.map((msg, index) => (
                        <div
                          key={index}
                          className={`message ${msg.direction === "outgoing"
                            ? "outgoing"
                            : "incoming"
                            }`}
                        >
                          <div className="message-content">
                            <p>{msg.message}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
            )}
            {typing && (
              <div className="typing">
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
              </div>
            )}
            <div className="message-input">
              <input
                type="text"
                placeholder="Message"
                value={msg}
                onFocus={() => {
                  setIsChat(true);
                }}
                onChange={handleOnChange}
                onKeyDown={(e) => e.key === "Enter" && handleSend()}
              />
              <button
                disabled={msg == "" ? true : false}
                className={`${msg == "" ? "disabled" : "send-button"}`}
                onClick={() => handleSend()}
                onKeyDown={(e) => e.key === "Enter" && handleSend()}
              >
                <img src={arrow} alt="" />
              </button>
              <button className="mic-icon" onClick={() => navigate("/speak")}>
                <img src={micicon} alt="" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Landing;
